const OPTIONS_FULL = `options--full`
const OPTION_VISIBLE = `options__option--visible`
const OPTION_INVISIBLE = `options__option--invisible`
// const SWITCHER_MARGIN = `options__switcher--margin`

const optionsBusinessBlock = document.querySelector(`.options--business`)
const switcher = optionsBusinessBlock.querySelector(`.options__switcher`)
const optionsBusiness = optionsBusinessBlock.querySelectorAll(`.options__option--invisible`)

const onSwitcherChange = () => {
  optionsBusinessBlock.classList.toggle(OPTIONS_FULL)
  optionsBusiness.forEach(item => {
    item.classList.toggle(OPTION_VISIBLE)
  })
}

if (switcher) {
  switcher.addEventListener(`input`, (evt) => {
    onSwitcherChange()
  })
}



const optionsGetStartedBlock = document.querySelector(`.options--getstarted`)
const switcherGetStarted = optionsGetStartedBlock.querySelector(`.options__switcher`)
const optionsGetStarted = optionsGetStartedBlock.querySelectorAll(`.options__option--mobile`)

const onGetStartedChange = () => {
  optionsGetStarted.forEach(item => {
    item.classList.toggle(OPTION_VISIBLE)
    item.classList.toggle(OPTION_INVISIBLE)
  })
}

if (switcherGetStarted) {
  switcherGetStarted.addEventListener(`input`, (evt) => {
    onGetStartedChange()
  })
}